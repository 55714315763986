import React, {useState} from "react"
import {graphql, useStaticQuery} from "gatsby";
import AboutNavigation from "../../components/about/aboutNavigation";
import HeroImage from "../../components/global/heroImage";
import InnerLayout from "../../components/global/innerLayout"
import PageTitle from "../../components/global/pageTitle";
import "./book-coauthors.scss"

const BookCoauthorsPage = () => {

    const data = useStaticQuery(graphql`
        {
          craft {
            page: entry(section: "biographiesIndex") {
              ... on Craft_biographiesIndex_biographiesIndex_Entry {
                id
                pageTitle
                uri
                aboutUsHeroImage {
                  ... on Craft_aboutUsHeroImage_image_BlockType {
                    image {
                      url(transform: "heroImageTransform")
                      ... on Craft_websiteGeneral_Asset {
                        altText
                      }
                    }
                    imageLink {
                      linkUrl
                      openInNewTab
                    }
                  }
                }
                seomatic {
                  metaTitleContainer
                  metaTagContainer
                  metaLinkContainer
                  metaScriptContainer
                  metaJsonLdContainer
                  metaSiteVarsContainer
                  frontendTemplateContainer
                }
              }
            }
            featuredBiographies:entries(section: "biographyPage", featuredBiography: true) {
                id
                uri
                ... on Craft_biographyPage_default_Entry {
                    title
                    jobTitle
                 }
            }
            biographies:entries(section: "biographyPage", featuredBiography: false, orderBy: "lastName asc") {
                id
                uri
                ... on Craft_biographyPage_default_Entry {
                    title
                    jobTitle
                    lastName
                 }
            }
          }
        }
    `)

    const [activeLetterRange, setActiveLetterRange] = useState(false)
    const [biographyQuery, setbiographyQuery] = useState(false)

    const getFeaturedBiographies = (biographies) => {
        return biographies.map(biography => (
            <li><a href={`/${biography.uri}`}>{biography.title}</a>{biography.jobTitle ? `, ${biography.jobTitle}` : null}</li>
        ))
    }

    const renderBiographies = (biographies) => {
        return biographies.map(biography => (
            <li><a
                href={`/${biography.uri}`}>{biography.title}</a>{biography.jobTitle ? `, ${biography.jobTitle}` : null}
            </li>
        ))
    }

    const getBiographies = (biographies) => {
        if (activeLetterRange) {
            return renderBiographies(biographies
                .filter(biography => (
                    activeLetterRange.some(activeLetter => biography.lastName.toLowerCase().startsWith(activeLetter.toLowerCase()))
                )))
        } else if (biographyQuery) {
            return renderBiographies(biographies
                .filter(biography => (
                    biography.title.toLowerCase().indexOf(biographyQuery.toLowerCase()) > -1
                )))
        } else {
            return renderBiographies(biographies)
        }
    }

    const clickLetterFilter = (event) => {
        let range = event.target.dataset.range

        if (range) {
            setActiveLetterRange(range.split(','))
            document.querySelectorAll('ul.paging li button').forEach(elem => elem.classList.remove('active'))
            document.querySelectorAll('ul.paging li button[data-range="'+ range +'"]').forEach(elem => elem.classList.add('active'))
            document.getElementById('biography-search').value = ''
            setbiographyQuery(false)
        } else {
            document.querySelectorAll('ul.paging li button').forEach(elem => elem.classList.remove('active'))
            document.querySelectorAll('ul.paging li button[data-range=""]').forEach(elem => elem.classList.add('active'))
            setActiveLetterRange(false)
        }
    }

    const searchBiographies = (event) => {
        let query = event.target.value

        if (query) {
            document.querySelectorAll('ul.paging li a').forEach(elem => elem.classList.remove('active'))
            setActiveLetterRange(false)
            setbiographyQuery(query)
        } else {
            document.querySelectorAll('ul.paging li a').forEach(elem => elem.classList.remove('active'))
            setbiographyQuery(false)
        }
    }
    
    return (
        <InnerLayout seomatic={data.craft.page.seomatic} entryId={data.craft.page.id}>
            <div className="midd book-coauthors-page">

                <PageTitle title={data.craft.page.pageTitle} />
                <HeroImage image={data.craft.page.aboutUsHeroImage}/>

                <div className="content-page">
                    <div className="container-small">

                        <div className="content-colum-row">
                            <div className="sidebar">
                                <AboutNavigation active={data.craft.page.uri} />
                            </div>
                            <div className="content-right">
                                <h3>Meet Our Book Coauthors</h3>

                                <div className="search-block">
                                    <input id="biography-search" type="text" placeholder="Search for a Name" onChange={searchBiographies}/>
                                </div>

                                <ul className="paging">
                                    <li><button onClick={clickLetterFilter} data-range="">All</button></li>
                                    <li><button onClick={clickLetterFilter} data-range="a,b,c">A-C</button></li>
                                    <li><button onClick={clickLetterFilter} data-range="d,e,f">D - F</button></li>
                                    <li><button onClick={clickLetterFilter} data-range="g,h,i">G - I</button></li>
                                    <li><button onClick={clickLetterFilter} data-range="j,k,l">J - L</button></li>
                                    <li><button onClick={clickLetterFilter} data-range="m,n,o">M - O</button></li>
                                    <li><button onClick={clickLetterFilter} data-range="p,q,r">P - R</button></li>
                                    <li><button onClick={clickLetterFilter} data-range="s,t,u">S - U</button></li>
                                    <li><button onClick={clickLetterFilter} data-range="v,w,x">V - X</button></li>
                                    <li><button onClick={clickLetterFilter} data-range="y,z">Y - Z</button></li>
                                </ul>

                                <ul className="bio-list featured">
                                    {getFeaturedBiographies(data.craft.featuredBiographies)}
                                </ul>

                                <ul className="bio-list">
                                    {getBiographies(data.craft.biographies)}
                                </ul>

                                <ul className="paging">
                                    <li><button onClick={clickLetterFilter} data-range="">All</button></li>
                                    <li><button onClick={clickLetterFilter} data-range="a,b,c">A-C</button></li>
                                    <li><button onClick={clickLetterFilter} data-range="d,e,f">D - F</button></li>
                                    <li><button onClick={clickLetterFilter} data-range="g,h,i">G - I</button></li>
                                    <li><button onClick={clickLetterFilter} data-range="j,k,l">J - L</button></li>
                                    <li><button onClick={clickLetterFilter} data-range="m,n,o">M - O</button></li>
                                    <li><button onClick={clickLetterFilter} data-range="p,q,r">P - R</button></li>
                                    <li><button onClick={clickLetterFilter} data-range="s,t,u">S - U</button></li>
                                    <li><button onClick={clickLetterFilter} data-range="v,w,x">V - X</button></li>
                                    <li><button onClick={clickLetterFilter} data-range="y,z">Y - Z</button></li>
                                </ul>
                                <div className="back-top"><a href="#top">Back to Top</a></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default BookCoauthorsPage
